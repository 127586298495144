export async function dynamicImportScript(url: string, winName: string) {
  return new Promise<void>((resolve, reject) => {
    const script = document.createElement("script");
    script.src = url;
    document.querySelector("head")?.appendChild(script);
    let i = 0;
    const interval = setInterval(() => {
      if (i * 100 > 10000) {
        clearInterval(interval);
        reject();
        return;
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (window?.[winName]) {
        clearInterval(interval);
        resolve();
        return;
      }
      i++;
    }, 100);
  });
}
