






















































import { Component, Emit, Vue } from "vue-property-decorator";
import DestinyCard from "@/views/destiny-draw/components/destinyCard/DestinyCard.vue";
import anime from "animejs";
import DestinyButton from "@/views/destiny-draw/components/destinyButton/DestinyButton.vue";
import { getChance, getCharacter } from "@/utils/common.util";
import type { IOrderStatusDetail, ISupplyBox } from "@/api/supply-package.api";
import InteractionLib from "@/utils/interaction.lib";
import { DOWNLOAD_APP } from "@/constant";

interface playOptions {
  items: Array<ISupplyBox>;
}

@Component({
  components: { DestinyButton, DestinyCard },
})
export default class SimpleDestinyRewardCopy extends Vue {
  private mobile = InteractionLib.mobile;
  private channel = localStorage.getItem("__channel") || "gamepower";

  get downloadURL() {
    if (this.mobile.ios) return DOWNLOAD_APP.ios;
    return this.channel in DOWNLOAD_APP.android
      ? DOWNLOAD_APP.android[this.channel]
      : DOWNLOAD_APP.android.gamepower;
  }

  cardWideHigh = 120;
  payType = false;

  show = false;
  visible = false;
  items: Array<ISupplyBox> = [];
  orderStatusDetail: IOrderStatusDetail | undefined = undefined;

  get shineStyle() {
    if (!this.upgradeList) return {};
    return this.upgradeList.need_num - this.upgradeList.mine_num <= 0;
  }

  get characterText() {
    if (!this.orderStatusDetail) return { text: "", icon: "" };
    const { ensure_character: character } = this.orderStatusDetail;
    const text = getCharacter(character, "", true);
    const icon = character < 5 ? "" : `__character_peak_${character - 4}_icon`;
    return { text, icon };
  }

  get possibleStyle() {
    if (!this.upgradeList) return {};
    return {
      background: `var(--card-color-${this.upgradeList.character})`,
    };
  }

  get isOne(): boolean {
    return this.items.length === 1;
  }

  @Emit()
  testClick(): void {
    this.handleClose();
  }

  @Emit()
  drawCard(num: number): number {
    this.handleClose();
    return num;
  }

  get character(): number {
    const { character = -1 } = this.oneCardInfo || {};
    return character;
  }

  get cardInfo(): {
    cover: string;
    character: number;
    rate: string;
    product_bazaar_price: number;
    product_price: number;
    price: number;
    id: number;
    title: string;
    type: number;
    duration?: string;
    key: number;
  }[] {
    return this.items.map((row, i) => ({
      key: i,
      type: row.type,
      product_price: row.product_price,
      product_bazaar_price: row.product_bazaar_price,
      id: row.id,
      cover: row.cover,
      character: row.level,
      title: row.title,
      duration: row.duration,
      price: row.price / 100,
      rate: `爆率 ${getChance(row.chance)}%`,
    }));
  }

  get oneCardInfo():
    | {
        coupon: any;
        product_id: number;
        cover: string;
        character: number;
        rate: string;
        price: number;
        id: number;
        title: string;
        type: number;
        product_bazaar_price: number;
        product_price: number;
      }
    | false {
    const row = this.items.find(Boolean);
    if (!row) return false;
    return {
      coupon: row.coupon,
      id: row.id,
      type: row.type,
      product_id: row.product_id,
      cover: row.cover,
      character: row.level,
      product_price: row.product_price,
      product_bazaar_price: row.product_bazaar_price,
      title: row.title,
      price: row.price / 100,
      rate: `爆率 ${getChance(row.chance)}%`,
    };
  }

  mounted(): void {
    this.handleCardWidth();
  }

  handleCardWidth(more = false): void {
    const { offsetWidth, clientWidth } = document.body;
    const width = offsetWidth || clientWidth;
    if (!more) {
      this.cardWideHigh = width / 3;
      return;
    }
    this.cardWideHigh = width / 4;
  }

  spc = false;
  upgradeList: any = "";

  async playAnimation(opt: playOptions): Promise<void> {
    const { items } = opt;
    this.items = items;
    this.show = false;
    this.visible = true;
    await this.$nextTick();
    this.show = true;
    await this.$nextTick();
    const num = items.length;
    this.handleCardWidth(num > 1);
    await this.$nextTick();
    return this.play(num);
  }

  private play(num = 1) {
    let i = 0;
    anime({
      targets: ".__destiny_reward .__mask",
      opacity: [1, 1, 1, 0],
      display: ["", "none"],
      easing: "easeOutElastic(1, .8)",
      duration: 1500,
      update: (anim) => {
        if (anim.currentTime > 1000 && i === 0) {
          i++;
          this.playNotWhite(num);
        }
      },
    });
  }

  private playNotWhite(num = 1): void {
    anime({
      targets: ".__destiny_reward",
      opacity: [0, 1],
      easing: "easeOutElastic(1, .8)",
    });
    anime({
      targets: ".__destiny_reward .__destiny_reward_body",
      opacity: [0, 1],
      easing: "easeOutElastic(1, .8)",
      duration: 300,
    });
    anime({
      targets: ".__destiny_reward .__destiny_card_box",
      opacity: [0, 1],
      scale: [0, num > 1 ? 0.9 : 1.5],
      duration: 800,
    });
  }

  async handleClose(): Promise<void> {
    document.documentElement.style.overflowY = "scroll";
    this.show = false;
    this.visible = false;
  }
}
