





































































































































































import { Component, Ref, Vue } from "vue-property-decorator";
import { getBlindBoxDetail, orderStatusV2 } from "@/api/activity.blind-box.api";
import SupplyPackageApi, { ISupplyBox } from "@/api/supply-package.api";
import DestinyCartoon from "@/views/destiny-draw/components/destinyFate/DestinyCartoon.vue";
import { Popup } from "vant";
import { cloneDeep } from "lodash";
import SimpleDestinyReward from "@/views/activity/components/SimpleDestinyRewardCopy.vue";
import { dynamicImportScript } from "@/utils/script.util";

@Component({
  components: {
    DestinyCartoon,
    SimpleDestinyReward,
    Popup,
  },
})
export default class BlindBox extends Vue {
  @Ref("destiny-cartoon")
  private readonly destinyCartoonRef: DestinyCartoon | undefined;

  @Ref("destiny-reward")
  private readonly destinyRewardRef: SimpleDestinyReward | undefined;
  show = false;
  serviceShow = false;
  private displacement = true;
  private private_id: string = this.$route.params?.private_id || "";
  private out_trade_no: string =
    (this.$route.query?.out_trade_no as string) || "";
  private items: Array<ISupplyBox> = [];

  private async getBlindBoxInfo() {
    const box = await getBlindBoxDetail(this.private_id);
    this.items = await SupplyPackageApi.getSupplyBoxList(box.id);
    const row = await orderStatusV2(this.out_trade_no);
    row.supply_id = row.supply_id
      ? String(row.supply_id).split(",").map(Number)
      : [];
    await this.$nextTick();
    await this.destinyCartoonRef?.playAnimation(
      (row.supply_id as unknown as number[]).find(Boolean) || 0,
      async () => {
        const items: ISupplyBox[] = [];
        (row.supply_id as unknown as number[]).forEach((id) =>
          items.push(cloneDeep(this.items.find((row) => row.id === id)!))
        );
        this.displacement = false;
        this.destinyRewardRef?.playAnimation({ items });
        // this.destinyCartoonRef?.restore();
      }
    );
  }

  toRule() {
    this.show = true;
  }

  toService() {
    this.serviceShow = true;
  }

  private mounted() {
    this.getBlindBoxInfo();
    dynamicImportScript(
      "https://s1.hdslb.com/bfs/cm/cm-sdk/static/js/track-collect.js",
      "bilicm"
    );
    const timer = setInterval(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (window?.["bilicm"]) {
        clearInterval(timer);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window?.["bilicm"]?.mbase?.report_data();
      }
    }, 100);
  }
}
